.Select-control,
.Select-value,
.Select-placeholder {
	padding: 9px !important;
}

.dropdownarea {
	z-index: 3;
	position: relative;
}

.ReactModal__Overlay {
	background-color: rgba(44, 44, 44, 0.75) !important;
	z-index: 100 !important;
}

.modal-header {
	/* color: #fff; */
	text-align: center;
	order: 0px;
	border-bottom: 0px;
	padding-top: 30px;
}

.modal-header .close {
	border: 0px;
	padding: 0;
	margin: 0;
}
.modal-title {
	margin: auto;
}
.close {
	opacity: 1 !important;
	border: 0px #fff !important;
}

.swiper-wrapper {
}

.btn-nopadding {
	padding: 0.25rem 0.5rem;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
	width: 100%;
}
.ReactModal__Overlay {
	overflow-x: hidden;
	overflow-y: auto;
}

.paragraphText {
	max-height: 100px;
	transition: max-height 0.15s ease-out;
	overflow: hidden;
}

.paragraphTextMore {
	max-height: 500px;
	transition: max-height 0.25s ease-in;
}

.btn-primary {
	background: #31901c !important;
	color: #fff !important;
	border-color: #31901c !important;
}
.paragraphText li {
	margin-left: 20px;
}
.paragraphText li::before {
	content: '•';
	color: #79a10c;
	margin-left: -17px;
	padding-right: 10px;
}

.editor-custom-style {
	height: 200px!important;
}